<template>
  <div>
    <b-card
      title="レッスンお申込みありがとうございます🎉"
    >
      <b-row>
        <b-col offset-md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="move"
          >
            トップページに戻る
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    move() {
    },
  },
}
</script>
